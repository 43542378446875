import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/deploys/bmo0l/ckuil5x5n011b0us87rt1glsg/source/src/components/layout.js";
import App from '../components/app';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`雪星仓储管理系统：标签生成器`}</h1>
    <h2>{`使用方法`}</h2>
    <ol>
      <li parentName="ol">{`在编辑框写好相应物品的，用空格或换行分割`}</li>
      <li parentName="ol">{`按 Ctrl+P 将下方标签打印到背胶纸上，你可以调整到你喜欢的 Scale。`}</li>
      <li parentName="ol">{`按需用透明胶或者热敏覆膜把打印表面保护起来`}</li>
      <li parentName="ol">{`裁剪`}</li>
      <li parentName="ol">{`贴在你需要的地方，比如各种物品或盒子表面`}</li>
    </ol>
    <h2>{`标签生成器（可打印）`}</h2>
    <App mdxType="App" />
    <h2>{`TODO:`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.wangyu.link/2019/04/23/2019-04-23/"
        }}>{`H5 端获取摄像头进行扫码(条形码/二维码) - 王雨的博客`}</a></li>
    </ul>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      